import React from "react"
import { graphql } from "gatsby"
// import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import Leaderboard from '../components/leaderboard'
// import basesBadge from '../images/challenge/badges/bases.png'
// import kitBadge from '../images/challenge/badges/kit.png'
// import macBadge from '../images/challenge/badges/mac.png'
// import macLowResBadge from '../images/challenge/badges/mac-low-res.png'
// import winBadge from '../images/challenge/badges/win.png'
// import winLowResBadge from '../images/challenge/badges/win-low-res.png'
// import appStoreBadge from '../images/challenge/badges/app-store.png'
// import playStoreBadge from '../images/challenge/badges/play-store.png'
import styles from "./torneo2021.module.css"
import icoFree from '../images/torneo2021/icoFree.png'
import icoCell from '../images/torneo2021/icoCell.png'
import icoLocal from '../images/torneo2021/icoLocal.png'
import icoPrice from '../images/torneo2021/icoPrice.png'
import logoReto2021 from '../images/torneo2021/2021_VikidzLogo.png'
import teacher from '../images/torneo2021/2021_vikingTeacher.png'
import leaderOn from  '../images/torneo2021/leaderOn.png'
// import leaderOff from  '../images/torneo2021/leaderOff.png'
import imageHeader from '../images/torneo2021/2021_image.png'
//import registerActive from '../images/torneo2021/VK_TorneoDeMate2021_registroactive.png'
import registerInActive from '../images/torneo2021/VK_TorneoDeMate2021_registroinactive.png'


// const badgeGroupClasses = 'w-1/2 sm:w-1/4'
// const badgeClasses = 'mx-auto p-2 block w-3/4 sm:w-full'

const RetoPage2021 = () => (
  <Layout isRetoPage={true}>
    <SEO
      lang="es"
      title="Torneo de Mate Vikidz 2021"
      description="Torneo de Mate Vikidz 2021"
    />

    <section className={styles.sectionLanding}>
      <div className="custom-container pt-32 pb-0 text-white">
        <img 
          // className="animation-pulse"
          className={styles.logo} 
          src={logoReto2021} 
          alt="El torneo es totalmente GRATUITO."
          />

        <p className="mt-16">
          El Torneo de Mate Vikidz School regresa en 2021 para fomentar la práctica de <b>MATEMÁTICAS</b> en educación <b>PRIMARIA</b> de una forma divertida para los estudiantes mientras compiten por premios.
        </p>
        <img className={styles.imageHeader} src={imageHeader} alt="Teacher" />

        <div className={styles.animDownload}>
          <a className="w-1/3 p-2 inline-block underline"
              href="/downloads"
              className={styles.downloadLink}>
                ¡Descarga la app!
          </a> 
        </div> 

        <div className="row">
          <div className="col-4">
            <p className={styles.headText}>
              ¡Habrá 6 grupos ganadores!
              </p>
            <p className="mt-5">
              Podrás ver tu posición en las tablas de líderes durante el torneo en el siguiente enlace:         
            </p>
            <div className="my-4">
              <a href="/leaderboard2021">
                <input className={styles.leaderButton} type="image" src={leaderOn} alt="LeaderBoard"/>
              </a>
            </div>

          <div className={styles.modalContainer}>
            <img 
              className={styles.modalImage} 
              src={icoFree} 
              alt="El torneo es totalmente GRATUITO."
            />
            <p className={styles.textImage}>El torneo es totalmente <b>GRATUITO.</b></p>

            <img 
              className={styles.modalImage} 
              src={icoCell} 
              alt="Se juega ONLINE a través de la app del torneo."
            />
            <p className={styles.textImage}>&nbsp;Se juega <b>ONLINE</b> a través de la app del torneo.</p>

            <img 
              className={styles.modalImage} 
              src={icoLocal} 
              alt="Para colegios de México, España y LATAM."
            />
            <p className={styles.textImage}>Para colegios de México, España y LATAM.</p>
        
            <img 
              className={styles.modalImage} 
              src={icoPrice} 
              alt="Ganarán los grupos con más gemas al final."
            />
            <p className={styles.textImage}>Ganarán los grupos con más gemas al final.</p>
          </div>
        </div>
        {/* 
          //
          //Segunda Columna
          //        
        */}
        <div className="col-4">
          <p className={styles.headText}>
          ¡Aparta la fecha!
          </p>
          <div className={styles.containerDiv}>
            <p className={styles.secText}>Registro</p>
            <p className={styles.secTextRight}>3 - 21 mayo</p>

            <p className={styles.secText}>Torneo</p>
            <p className={styles.secTextRight}>31 mayo - 25 junio</p>

            <p className={styles.secText}>Premiación</p>
            <p className={styles.secTextRight}>30 junio</p>
          </div>
          <img className={styles.logoTeacher} src={teacher} alt="Teacher" />
        </div>
          
          <div>
            <p className={styles.headText}>
             
            </p>
            {/* <a href="/torneo-registro"> */}
            <input className={styles.groupRegister} type="image" src={registerInActive} alt="Registro" disabled={true}/>
            {/* </a> */}
          </div>

          

        </div>


        {/* <p className="mt-6 text-lg font-bold">
          Descargas
        </p> */}
        {/* <div className="my-4 flex flex-wrap">
          <div className={badgeGroupClasses}>
            <a href="/torneo/kit-competidor.zip" className={badgeClasses} download>
              <img className="block" src={kitBadge} alt="Kit Competidor" />
            </a>

            <a href="/torneo/bases.pdf" className={badgeClasses} download>
              <img className="block" src={basesBadge} alt="Bases" />
            </a>
          </div>

          <div className={badgeGroupClasses}>
            <a
              href="https://drive.google.com/file/d/1VZI4EADtnHGjlBifTowi0peBAvtOtSQW/view"
              target="_blank"
              rel="noreferrer noopener"
              className={badgeClasses}
            >
              <img className="block" src={macBadge} alt="Instalador Mac" />
            </a>

            <a
              href="https://drive.google.com/file/d/1f6hrnR_mMs1--42ZpC-US9hJV_mCxswl/view"
              target="_blank"
              rel="noreferrer noopener"
              className={badgeClasses}
            >
              <img className="block" src={macLowResBadge} alt="Instalador Mac - Baja resolución" />
            </a>
          </div>

          <div className={badgeGroupClasses}>
            <a
              href="https://drive.google.com/file/d/1Tt-1nrToIVjxaTyMcRuWhuJQUim97Q1_/view"
              target="_blank"
              rel="noreferrer noopener"
              className={badgeClasses}
            >
              <img className="block" src={winBadge} alt="Instalador Windows" />
            </a>

            <a
              href="https://drive.google.com/file/d/1EHDJWeMIOCWsgD4f3TjhQwQLC9tC6170/view"
              target="_blank"
              rel="noreferrer noopener"
              className={badgeClasses}
            >
              <img className="block" src={winLowResBadge} alt="Instalador Windows - Baja resolución" />
            </a>
          </div>

          <div className={badgeGroupClasses}>
            <a
              href="https://apps.apple.com/us/app/torneo-vikidz/id1500529123?ls=1"
              target="_blank"
              rel="noreferrer noopener"
              className={badgeClasses}
            >
              <img className="block" src={appStoreBadge} alt="App iOS" />
            </a>

            <a
              href="https://play.google.com/store/apps/details?id=io.vikidz.challenge"
              target="_blank"
              rel="noreferrer noopener"
              className={badgeClasses}
            >
              <img className="block" src={playStoreBadge} alt="App Android" />
            </a>
          </div>
        </div> 

        <div className="flex items-center justify-between">
          <a className="w-1/3 p-2 inline-block underline" href="/torneo/aviso-de-privacidad-torneo-vikidz.pdf" download>
            Aviso de privacidad
          </a>

          <a className="w-1/3 p-2 inline-block underline" href="/torneo/guia-de-instalacion-mac.pdf" download>
            Guía de instalación<br />Mac
          </a>

          <a className="w-1/3 p-2 inline-block underline" href="/torneo/guia-de-instalacion-pc.pdf" download>
            Guía de instalación<br />Windows
          </a>
        </div>*/}
      </div>
    </section>
    {/* <Leaderboard />  */}
    </Layout>

    

  //   <section className="bg-challenge-form py-32 text-blue-darkest">
  //     <div className={"custom-container"}>
  //       <h3 className="subheading">Registro</h3>

  //       <p className="mb-8">Registra tus salones para competir en el torneo ahora mismo.</p>

  //       <form name="reto" method="POST" data-netlify="true" netlify-honeypot="bot-field">
  //         <input type="hidden" name="form-name" value="reto"/>

  //         <div className="hidden">
  //           <label>
  //             Don’t fill this out if you're human:
  //             <input type="hidden" name="bot-field"/>
  //           </label>
  //         </div>

  //         <div className="form-field">
  //           <label className="form-label text-blue-darkest">Colegio</label>
  //           <span className="form-label-help block text-blue-darker">Escribe el nombre del Colegio y/o su página web</span>
  //           <input className="form-control" name="colegio" type="text"/>
  //         </div>

  //         <div className="flex flex-wrap md:flex-no-wrap">
  //           <div className="form-field md:mr-4 w-full md:w-6/12">
  //             <label className="form-label text-blue-darkest">Número de alumnos</label>
  //             <input className="form-control" name="alumnos" type="number"/>
  //           </div>

  //           <div className="form-field md:mr-4 w-full md:w-3/12">
  //             <label className="form-label text-blue-darkest">Grado</label>
  //             <input className="form-control" name="grado" type="text"/>
  //           </div>

  //           <div className="form-field w-full md:w-3/12">
  //             <label className="form-label text-blue-darkest">Ciudad</label>
  //             <input className="form-control" name="ciudad" type="text"/>
  //           </div>
  //         </div>

  //         <div className="form-field w-full md:w-2/3">
  //           <label className="form-label text-blue-darkest">Nombre del profesor representante</label>
  //           <input className="form-control" name="profesor" type="text"/>
  //         </div>

  //         <div className="flex flex-wrap md:flex-no-wrap">
  //           <div className="form-field flex flex-col justify-between md:mr-4 w-full md:w-1/2">
  //             <label className="form-label text-blue-darkest">Email</label>
  //             <input className="form-control" name="email" type="email"/>
  //           </div>

  //           <div className="form-field w-full md:w-1/2">
  //             <label className="form-label text-blue-darkest">Teléfono</label>
  //             <span className="form-label-help block text-blue-darker">Incluye LADA</span>
  //             <input className="form-control" name="telefono" type="tel"/>
  //           </div>
  //         </div>

  //         <button type="submit" className="btn btn--blue mt-2">Registrarse</button>
  //       </form>
  //     </div>
  //   </section>
  // </Layout>
)

export const query = graphql`
  query {
    logo: file(relativePath: { eq: "challenge/logo.png"}) {
      childImageSharp { fluid(maxWidth: 500) { ...GatsbyImageSharpFluid } }
    }
  }
`

export default RetoPage2021
